// Style for the Error Pages

.error-page > .container .title {
    font-size: 12em;
    color: $default;
    letter-spacing: 14px;
    font-weight: 700;
}

.error-page {
  .page-500 {
    .low {
      position: relative;
      bottom: -200px;
    }
  }
}
  

@include media-breakpoint-down(sm) {

    .error-page {
      .page-header.page-500 .page-header-image {
        background-position: 47%;
      }
    }
  
}

.page-header {
    min-height: 100vh;
    max-height: 999px;
    padding: 0;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;

    .carousel .carousel-indicators {
      bottom: 60px;
    }

    >.container,
    >.container-fluid {
      padding-top: 12vh;
      padding-bottom: 40px;
    }

    .page-header-image {
        position: absolute;
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    & > .content {
        margin-top: 11%;
        text-align: center;
        margin-bottom: 50px;
    }

    .signup-page & {
        max-height: fit-content;
    }

    &.header-filter {
      position: relative;

      .container {
        z-index: 2;
        position: relative;
      }

      &:before {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        display: block;
        left: 0;
        top: 0;
        content: "";
        background-color: rgba(0,0,0,.5);
      }
    }

    .content-center {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        color: $white;
        padding: 0 15px;
        width: 100%;

        &.brand {
          width: 47%;
        }

    }

    footer {
      position: absolute;
      bottom: 0;
      width: 100%;
    }

    .container {
        height: 100%;
        z-index: 1;
    }

    .category,
    .description {
        color: $secondary;
    }

    &.page-header-small {
        min-height: 60vh;
        max-height: 440px;
    }

    &.page-header-mini {
        min-height: 40vh;
        max-height: 340px;
    }

    .title {
        margin-bottom: 15px;
    }
    .title + h4 {
        margin-top: 10px;
    }

    &.header-filter:after {
      background: rgba(0,0,0,.5);
    }

}
