// Style for the BlogPost Page

.blog-post {
    .header-1 .navbar {
      display: none;
    }
  
    .glide {
      .glide__arrow {
        color: $default;
      }
    }
  
    .floating-box {
      .blockquote {
        border-left: none;
  
        .blockquote-footer {
          bottom: auto;
        }
      }
    }
    .carousel .carousel-indicators {
      bottom: 60px;
    }
  
  }