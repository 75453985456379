$card-background-height:            330px;
$card-background-height-sm:         380px;
$card-background-height-lg:         550px;


$margin-bottom:                     10px !default;
$margin-lg-vertical:                30px !default;
$margin-base-vertical:              15px !default;

  // Blog Cards

.card-blog {
    overflow: hidden;
  
    &.card-plain {
      .card-body {
        padding: 15px 0;
      }
    }
  
    .card-image .img {
      width: 100%;
      background-size: cover;
      background-position: 50% 50%;
    }
  
    .pattern {
      margin-top: -45%;
    }
  
    &[data-background="pattern"] {
      .card-footer {
        border-top: none;
      }
    }
    .card-body {
        .card-footer {
          position: relative;
          bottom: 0;
          left: 0;
          background-color: transparent !important;
          width: 100%;
          color: $white;
          padding: 5px 0 0 0 !important;
          border-top: none !important;
      
        }
      }

        
  .pattern {
    width: 100%;
  }
  
  .stats{
    font-size: .8em;
  
      i{
        margin-right: 5px;
        position: relative;
        top: 2px;
  
      }
  
    &.stats-right {
      float: right;
      line-height: 30px;
      position: relative;
    }
  }
  
  .author {
    position: relative;
    display: inline-block;
    text-align: left;
  
    .avatar {
      width: 30px;
      height: 30px;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 5px;
    }
  
    .meta {
      color: #a49e9e;
      font-size: .8em;
    }
  }

  }


.card-background {
    background-position: center center;
    background-size: cover;
    text-align: center;
  
    .card-body {
      position: relative;
      z-index: 2;
      min-height: $card-background-height;
      padding-top: 60px;
      padding-bottom: 60px;
  
      .content-bottom {
        position: absolute;
        bottom: $margin-bottom;
        text-align: left;
      }
    }
  
    &.card-blog {
      overflow: hidden;
      height: $card-background-height;
    }
  
    &.card-grande {
      height: $card-background-height-lg;
  
      .card-body {
        height: $card-background-height-lg;
        text-align: left;
        padding-left: 30px;
  
        .author {
          margin-bottom: 20px;
        }
      }
      .full-background {
        height: $card-background-height-lg;
      }
  
      &:after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        z-index: 1;
        width: 100%;
        display: block;
        content: "";
        background: rgba(0,0,0, 0.6);
      }
  
      @include media-breakpoint-up(lg) {
        .card-body {
          padding: 60px;
        }
      }
    }
  
    .full-background {
      background-position: center center;
      background-size: cover;
      text-align: center;
      margin-bottom: $margin-lg-vertical;
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: $border-radius-lg;
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      transition: all 1s cubic-bezier(.2,1,.22,1);
      -webkit-transition: all 1s cubic-bezier(.2,1,.22,1);
    }
  
    &[data-animation="true"],
    &[data-animation="zooming"] {
      &:hover {
        .full-background {
          transform: scale(1.07);
          -webkit-transform: scale(1.07);
        }
      }
    }
  
    &[data-animation="zooming"] {
      transition: all .3s cubic-bezier(.2,1,.22,1);
      -webkit-transition: all .3s cubic-bezier(.2,1,.22,1);
  
      a:not(.btn) {
        height: 100%;
      }
  
      .card-body {
        padding-bottom: $margin-lg-vertical;
        margin: initial;
        height: 100%;
      }
  
      &:hover {
        transform: scale(1.07);
        -webkit-transform: scale(1.07);
        z-index: 2;
      }
    }
  
      &.card-background-product .card-body {
        .card-title {
          margin-top: 30px;
        }
      }
  
      .stats {
          color: $white;
      }
  
      .card-footer {
          .stats-link > a{
              color: $white;
              line-height: 1.9;
          }
      }
  
      .category,
      .card-description,
      small {
          color: $gray-500;
      }
  
      .card-title {
        color: $white;
      }
  
      &:not(.card-pricing) .btn {
          margin-bottom: 0;
      }
  
      &:after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        z-index: 1;
        width: 100%;
        display: block;
        content: "";
        background: rgba(0,0,0, 0.6);
      }
  
    .content-center {
      margin-top: 120px;
  
      .card-category {
        margin: 30px auto 5px;
      }
  
      .avatar {
        width: 40px;
        height: 40px;
      }
    }
  }
  
  .card-header.card-background {
    &:after {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
  
    .card-title, .badge, h1, h2, h3, h4, h5, h6, p {
      position: relative;
      z-index: 2;
    }
  }
  
  
 

  ////Full CARDS

  ///Big
  .blogs-4 {
    .card {
      margin-bottom: 0px;
      text-align: center;
      height: 530px;
      border-radius: 0;
  
  
      .full-background {
        border-radius: 0;
      }
    }
  
    [class^=col-] {
      padding: 0;
    }
  
    .row-below {
      .card {
        margin-top: 0 !important;
      }
    }
  }

  /// small 
  .blogs-4-small{
    .card {
      margin-bottom: 0px;
      text-align: center;
      // height: 530px;
      border-radius: 0;
  
  
      .full-background {
        border-radius: 0;
      }
    }
  
    [class^=col-] {
      padding: 0;
    }
  
    .row-below {
      .card {
        margin-top: 0 !important;
      }
    }
  }


  //// BIG non-full cards 

.blogs-2 {
  .card {
    height: 530px;
  }
}



  ///// SMALL CARDS


  //
// Profile card
//

.card-profile {

  &[data-background="cover"] {
    overflow: hidden;

    .pattern {
      position: absolute;
      top: -38%;
    }
  }
  .card-header {
    background-position: 50%;
    background-size: cover;
    margin-bottom: 60px;
    width: 100%;
    height: 150px;
    border: none;
  }

  .card-avatar {
      max-width: 130px;
      max-height: 130px;
      margin: 70px auto 0;

      img {
        width: 100%;
        border: 3px solid $card-bg;
        transform: scale(1);
        border-radius: 50% !important;
        transition: $transition-base;

        &:hover {
          transform: scale(1.03);
        }
      }

      & + .card-body{
          margin-top: 15px;
      }
  }

  &.card-plain{
    .card-header {
      border-radius:$border-radius;
    }
  }
  .card-body{
      .card-avatar{
          margin: 0 auto 30px;
      }
  }

  &[data-background="full"] {
    img {
      height: 100%;
      width: 100%;
    }

    .card-image {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }

    .card-body {
      position: absolute;
      bottom: 15px;
      background: white;
      width: calc(100% - 30px);
      margin: 0 15px;
      z-index: 1;
      border-radius: 3px;
    }
    .card-image {

      .title {
        position: absolute;
        left: 20px;
        top: 15px;
        color: $white;
        font-size: 16px;
      }

      .dropdown {
        position: absolute;
        right: 0;
        top: 10px;
      }

      .title,
      .dropdown {
        z-index: 2;

        .btn {
          color: $white;
        }
      }

      &:after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        z-index: 1;
        width: 100%;
        display: block;
        content: "";
        background: rgba(0, 0, 0, .1);
        border-radius: $border-radius !important;
      }

      &.no-mask {
        &:after {
          background: none;
        }
      }
    }

    .table {
      margin-bottom: 0;

      td {
        border: none;
        padding: 7px;
        padding-right: 0;
      }
    }
  }

  &[data-image="profile-image"] {
    overflow: hidden;

    .card-header {
      height: 100%;
      margin-bottom: 0;
      padding: 0;

      &::after {
        content: "";
        width: 24%;
        overflow: hidden;
        position: absolute;
        left: 32%;
        top: 5%;
        height: 90%;
        background-color: $white;
        transform: rotate(80deg);
        z-index: 1;
      }
    }

    .lead,
    h4 {
      position: relative;
      z-index: 3;
    }

    .lead {
      margin-top: 0;
    }

    .card-image {
      position: relative;
      height: 250px;

      .dropdown {
        position: absolute;
        right: 10px;
        top: 10px;
      }

      img {
        height: 100%;
        max-width: 100%;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }

      .title,
      .dropdown {
        z-index: 2;

        .btn {
          color: $white;
        }
      }

      &.no-mask {
        &:after {
          background: none;
        }
      }
    }

    hr {
      margin-right: 0;
      margin-left: auto;
    }

    .job-title {
      margin-bottom: 10px;
      z-index: 3;
      position: relative;
    }

    .table {
      margin-bottom: 0;

      td {
        border: none;
        padding: 7px;
        padding-right: 0;

        i {
          margin-right: 5px;
          font-size: 16px;
          color: $primary;
          top: 3px;
          position: relative;
        }
      }
    }
  }

  &[data-image="img-rounded"] {
    margin-top: 50px;
    text-align: center;

    .card-avatar {
      margin: -50px auto 0;
      border-radius: 50%;
      overflow: hidden;
      padding: 0;
      box-shadow: 0 16px 38px -12px rgba(0,0,0,.56),
                  0 4px 25px 0 rgba(0,0,0,.12),
                  0 8px 10px -5px rgba(0,0,0,.2);

      img {
        width: 100%;
        height: auto;
      }
    }

    .card-description {
      text-align: justify;
    }
  }

  &[data-image="img-raised"] {
    margin-top: 30px;

    .card-header-image {
      max-height: 215px;
      position: relative;
      padding: 0;
      z-index: 1;
      margin-left: 15px;
      margin-right: 15px;
      margin-top: -30px;

      a {
        display: block;

        img {
          width: 100%;
          border-radius: 6px;
          pointer-events: none;
          box-shadow: 0 5px 15px -8px rgba(0,0,0,.24),
                      0 8px 10px -5px rgba(0,0,0,.2);
        }
      }
    }

    .card-description {
      margin: 15px auto -10px;
    }

    .card-body + .card-footer {
        margin-top: -15px;
        border-top: none;
    }

    .card-footer {
        .btn.btn-just-icon {
          font-size: 20px;
          padding: 12px 12px;
          line-height: 1em;
        }
    }



    .card-title {
      position: absolute;
      bottom: 0px;
      left: 15px;
      color: $primary;
      font-size: 1.125rem;
    }
  }


}

.card-profile-stats {
    padding: 1rem 0;

    > div {
        text-align: center;
        margin-right: 1rem;
        padding: .875rem;

        &:last-child {
            margin-right: 0;
        }

        .heading {
            font-size: 1.1rem;
            font-weight: bold;
            display: block;
        }
        .description {
            font-size: .875rem;
            color: $gray-500;
        }
    }
}

.card-profile-actions {
    padding: .875rem;
}
