.icon-actions {
  a i + span{
    margin-left: 7px;
  }
  a + a {
    margin-left: 4px;
  }

  div i + span{
    margin-left: 7px;
  }
  div + div {
    margin-left: 4px;
  }
}
