/**  Card  **/
.qr-card {
	max-width: 22rem;
	padding: 1.2rem;
	border-radius: 1.5rem;
	text-align: center;
	background-color: var(--white);
	box-shadow: var(--shadow);
}
.qr-card > * {
	margin-bottom: 1.5rem;
}
.qr-card .qr-box {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 20rem;
	border-radius: .8rem;
	overflow: hidden;
}
.qr-card .qr-box::before,
.qr-card .qr-box::after {
	content: '';
	position: absolute;
	top: -12rem;
	right: 6rem;
	width: 22rem;
	height: 22rem;
	border-radius: 100%;
	// background-color: rgba(255,255,255,.08);
	z-index: 99;
}
.qr-card .qr-box::after {
	top: 14rem;
	right: -8rem;
	width: 20rem;
}
.qr-card .qr-box canvas {
	border-radius: .8rem;
	transition: var(--cubic-bezier);
	z-index: 98;
}
// .bg-gradient-livenyte_ canvas{
//     background: linear-gradient(87deg, #FF4F83 0, #ff644f 100%) !important;
// }


