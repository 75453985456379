/*!

=========================================================
* LiveNyte
=========================================================

*/

.react-select {
  .react-select__menu{
    top: 105%;
    max-width: 94%;
    width: 94%;
    right: 3%;
    border-radius: 5px;
    border: 0;
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
    border-radius: 0.125rem;
    -webkit-transition: all 150ms linear;
    -moz-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    -ms-transition: all 150ms linear;
    transition: all 150ms linear;
    background-color: $white;
    box-sizing: border-box;
    max-height: 200px;
    position: absolute;
    z-index: 2;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 10px;
    margin-bottom: unset;
    margin-top: unset;
    &:before{
      display: inline-block;
      position: absolute;
      width: 0;
      height: 0;
      vertical-align: middle;
      content: "";
      top: -5px;
      left: 10px;
      right: auto;
      color: $white;
      border-bottom: .4em solid;
      border-right: .4em solid transparent;
      border-left: .4em solid transparent;
    }

    &:after{
      border-bottom: .4em solid $white;
      border-right: .4em solid transparent;
      border-left: .4em solid transparent;
      content: "";
      display: inline-block;
      position: absolute;
      top: -5px;
      left: 10px;
    }
  }
  .react-select__menu-list {
    max-height: 198px;
    overflow-y: auto;
    padding-bottom: unset;
    padding-top: unset;
    position: relative;
  }
  .react-select__option {
    font-size: 0.78em;
    padding-top: .6rem;
    padding-bottom: .6rem;
    margin-top: 5px;
    -webkit-transition: all 150ms linear;
    -moz-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    -ms-transition: all 150ms linear;
    transition: all 150ms linear;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: block;
    outline: none;
    color: #292b2c;
  }
  .react-select__menu{
    .react-select__option{
      &,&:active{
        background-color: transparent;
      }
      &.react-select__option--is-selected{
        &,&.react-select__option--is-focused{
          &,&:active{
            background-color: $gray-400;
          }
        }
      }
      &.react-select__option--is-focused {
        &,&:active{
          background-color: $gray-200;
        }
      }
      &.react-select__option--is-disabled {
        &,&:active{
          color: #cccccc;
          cursor: default;
        }
      }
    }
  }
  .react-select__control{
    // border-radius: 19px;
    min-height: 46px;
    height: 46px;
    box-sizing: border-box;
    border-collapse: separate;
    display: table;
    width: 100%;
    border: 1px solid $border-color;
    &,&:hover{
      border: 1px solid $border-color;
      box-shadow: none;
      background-color: transparent;
    }
    &.react-select__control--is-focused{
      .react-select__dropdown-indicator{
        &:after{
          transform: rotate(180deg);
          top: -4px;
        }
      }
      &,&:hover{
        border: 1px solid $primary;
        box-shadow: none;
      }
    }
    .react-select__value-container--has-value{
      .react-select__multi-value{
        display: inline-flex;
        border-radius: 19px;
        background-color: transparent;
        & + div:not(.react-select__multi-value){
          display: inline-flex;
        }
        .react-select__multi-value__label{
          font-size: 0.9em;
        }
      }
      .react-select__single-value{
        top: 48%;
        color: $black;
      }
    }
    .react-select__placeholder{
      color: $gray-700;
      line-height: normal;
      font-size: 0.8571em;
      top: 48%;
    }
    .react-select__value-container,
    .react-select__indicators{
      display: table-cell;
    }
    .react-select__indicators {
      width: 60px;
      padding-right: 10px;
      align-items: flex-end;
      vertical-align: middle;
      text-align: right;
      .react-select__indicator-separator{
        display: none;
      }
      .react-select__indicator {
        padding: 0;
        display: inline-block;
      }
      .react-select__dropdown-indicator{
        &:after{
          transition: all 150ms ease 0s;
          border-color: #999 transparent transparent;
          border-style: solid;
          border-width: 5px 5px 2.5px;
          display: inline-block;
          height: 0;
          width: 0;
          position: relative;
          content: "";
        }
        &:hover:after{
          border-top-color: $btn-link-disabled-color;
        }
        & > * {
          display: none;
        }
      }
      .react-select__clear-indicator{
        &:before{
          content: "\D7";
          display: inline-block;
          font-size: 18px;
          line-height: 1;
          top: 2px;
          position: relative;
          margin-right: 3px;
        }
        & > * {
          display: none;
        }
      }
    }
  }
  &.primary{
    .react-select__control{
      .react-select__value-container--has-value{
        .react-select__single-value,
        .react-select__multi-value__label{
          color: $primary;
        }
      }
    }
    .react-select__multi-value{
      border: 1px solid $primary;
      .react-select__multi-value__remove{
        color: $primary;
        border-left: 1px solid $primary;
        &:hover{
          color: $primary;
          background-color: transparent;
        }
      }
    }
  }
  &.default{
    .react-select__control{
      .react-select__value-container--has-value{
        .react-select__single-value,
        .react-select__multi-value__label{
          color: $gray-800;
          font-size: 1.0625rem;
          font-weight: 600;
        }
      }
    }
    .react-select__multi-value{
      border: 1px solid $gray-800;
      .react-select__multi-value__remove{
        color: $primary;
        border-left: 1px solid $gray-800;
        &:hover{
          color: $gray-800;
          background-color: transparent;
        }
      }
    }
  }
  &.white{
    .react-select__control{
      .react-select__value-container--has-value{
        .react-select__single-value,
        .react-select__multi-value__label{
          color: white;
          font-size: 1.0625rem;
          font-weight: 600;
        }
      }
    }
    .react-select__multi-value{
      border: 1px solid white;
      .react-select__multi-value__remove{
        color: white;
        border-left: 1px solid white;
        &:hover{
          color: white;
          background-color: transparent;
        }
      }
    }
  }
  &.success{
    .react-select__control{
      .react-select__value-container--has-value{
        .react-select__single-value,
        .react-select__multi-value__label{
          color: $success;
        }
      }
    }
    .react-select__multi-value{
      border: 1px solid $success;
      .react-select__multi-value__remove{
        color: $success;
        border-left: 1px solid $success;
        &:hover{
          color: $success;
          background-color: transparent;
        }
      }
    }
  }
  &.info{
    .react-select__control{
      .react-select__value-container--has-value{
        .react-select__single-value,
        .react-select__multi-value__label{
          color: $info;
        }
      }
    }
    .react-select__multi-value{
      border: 1px solid $info;
      .react-select__multi-value__remove{
        color: $info;
        border-left: 1px solid $info;
        &:hover{
          color: $info;
          background-color: transparent;
        }
      }
    }
  }
  &.warning{
    .react-select__control{
      .react-select__value-container--has-value{
        .react-select__single-value,
        .react-select__multi-value__label{
          color: $warning;
        }
      }
    }
    .react-select__multi-value{
      border: 1px solid $warning;
      .react-select__multi-value__remove{
        color: $warning;
        border-left: 1px solid $warning;
        &:hover{
          color: $warning;
          background-color: transparent;
        }
      }
    }
  }
  &.danger{
    .react-select__control{
      .react-select__value-container--has-value{
        .react-select__single-value,
        .react-select__multi-value__label{
          color: $danger;
        }
      }
    }
    .react-select__multi-value{
      border: 1px solid $danger;
      .react-select__multi-value__remove{
        color: $danger;
        border-left: 1px solid $danger;
        &:hover{
          color: $danger;
          background-color: transparent;
        }
      }
    }
  }
  .react-select__multi-value{
    .react-select__multi-value__remove{
      position: relative;
      color: $gray-500;
      &:hover{
        color: $gray-700;
        background-color: transparent;
      }
    }
  }
  &.react-select--is-disabled{
    .react-select__multi-value--is-disabled{
      .react-select__multi-value__remove {
        display: none;
      }
      &.react-select__multi-value{
        background-color: #fcfcfc;
        border: 1px solid #e3e3e3;
      }
    }
    .react-select__control--is-disabled{
      background-color: #f9f9f9;
    }
  }
}
