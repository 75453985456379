

.draftPreview {

    p {
        font-weight: $font-weight-normal
    }
    max-width: 750px;
    margin: 0 auto

  }

.draft-multiPreview {

    p {
        font-weight: $font-weight-normal
    }
    max-width: 650px;
    margin: 0 auto

  }

  .draft-body {
    img {
        width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
        @include border-radius($card-inner-border-radius);
    }
    p {
      margin-bottom: 0.25rem;
    }
  }

  .asset-preview {

    p {
        font-weight: $font-weight-normal
    }
    margin: 0 auto

  }

  .asset-card {
    p {
      font-weight: $font-weight-normal
    }
  }

  .draft-body {
    img {
        width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
        @include border-radius($card-inner-border-radius);
    }
  }



  .draft-title {
    font-size: $font-size-xxl !important;
    font-weight: $font-weight-extra-bold;
    line-height: $input-line-height-lg;
    color: $gray-800 !important;
  }

  .draft-subtitle {
    font-size: $h3-font-size !important;
    color: $input-color;
    line-height: $input-line-height-lg;
  }

  @media (max-width: 767px) {
    .draft-title {
        font-size: $font-size-xl !important;
        line-height: $input-line-height-lg;
        color: $gray-800 !important;
      }
    
      .draft-subtitle {
        font-size: $font-size-base;
        color: $input-color;
        line-height: $input-line-height-lg;
      }
}
