.sidenav {
    &.fixed-left + .main-content .react-jinke-music-player-main .music-player-panel {
        margin-left: $navbar-vertical-width;
        @include transition($transition-base);
        .player-content {
            margin-right: $navbar-vertical-width;
            @include transition($transition-base);
        }
    }
    &.fixed-right + .main-content .react-jinke-music-player-main .music-player-panel  {
        margin-right: $navbar-vertical-width;
        @include transition($transition-base);
    }
}

.g-sidenav-pinned {
    .sidenav {
        @include media-breakpoint-up(xl) {
            &.fixed-left + .main-content .react-jinke-music-player-main .music-player-panel {
                margin-left: $navbar-vertical-open-width;
                .player-content {
                    margin-right: $navbar-vertical-open-width;
                }
            }
            &.fixed-right + .main-content  .react-jinke-music-player-main .music-player-panel {
                margin-right: $navbar-vertical-open-width;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .sidenav {
        &.fixed-left + .mainContent .react-jinke-music-player-main .music-player-panel {
            margin-left: 0 !important;
            .player-content {
                margin-right: 0 !important;
            }
        }
    }
}

.audio-lists-panel {
    &-header{
        &-title {
            color: #fff
        }
    }
    .audio-item {
        &.playing {
            &,
            svg {
            color: $primary !important;
            }
        }
        &:hover,
        &:active {
          .group:not([class='.player-delete']) {
            svg {
              color: $primary !important;
            }
          }
        }
    }
}

.react-jinke-music-player-main {
    ::-webkit-scrollbar-thumb {
        background-color: $primary !important;
      }

    .lyric-btn {
        &-active {
          &,
          svg {
            color: $primary !important;
          }
        }
      }

      svg {    
        &:hover,
        &:active {
          color: $primary !important;
        }
      }
      .music-player-panel {
        .panel-content {
          box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
            .img-content {
              background-size: cover !important;
              background-position: center !important;
            }
  
            .rc-slider{
                &-handle,
                &-track {
                    background-color: $primary !important;
                }
                &-handle {  
                &:active {
                    box-shadow: 0 0 2px $primary !important;
                }
                }               
            }
            .progress-bar-content {
                .progress-bar {
                    // background-color: $primary !important;

                }
            }
            .player-content {
                > .group {
                    > i {
                        color: $primary !important;
         
                      }
                }
                .loop-btn {
                    &.active {
                      color: $primary !important;
                    }
                }
                
                .audio-lists-btn {
                    > .group:hover {
                      &,
                      & > svg {
                        color: $primary !important;
                      }
                    }
                  }

            }

        }
      
    }

    &.light-theme {
        svg {
          color: $gray-700 !important;
    
          &:hover,
          &:active {
            color: lighten($primary, 5%) !important;
          }
        }

        .rc-switch-checked {
          background-color: $primary !important;
          border: 1px solid $primary !important;
        }
    
        .audio-lists-panel {
          .audio-item {
    
            &:nth-child(2n + 1) {
            }
    
            &.playing {    
              &,
              svg {
                color: $gray-700 !important;
              }
            }
            @include media-breakpoint-down(lg) {    
              &.playing {    
                &,
                svg {
                  color: $gray-700 !important;
                }
              }
            }
          }
        }
        .audio-item {
            &:hover,
            &:active {
      
              svg {
                color: $primary !important;
              }
            }
      
            &.playing {
      
              svg {
                color: $primary !important;
              }
      
              .player-singer {
                color: $primary !important;
              }
            }
        }
        .play-mode-title {
            color: $primary !important;
        }
    }
}

.react-jinke-music-player {
    &:focus {
      outline: none;
    }
  
    .music-player {
      &-controller {
        color: $primary !important;
        background-size: cover !important;
        background-position: center !important;

        .music-player-controller-setting {
          background: fade($primary !important, 30%) !important;
        }
      }
    }

    .music-player-controller {
      color: $primary;
      .music-player-controller-setting {
        background:  rgba($gray-800, 0.7) !important;
      }
    }
  
    .audio-circle-process-bar {
      circle[class='stroke'] {
        stroke: $primary !important;
      }
    }
}

.react-jinke-music-player-main {
  .loading {
    svg {
        color: $primary !important;
      }
    }
    .light-theme {
      svg {
        color: $primary !important;

      }
  }
}

.react-jinke-music-player-mobile {
    &-play-model-tip {
        background-color: $primary !important;
    }
    &-cover .cover {
      height: 100% !important;
    }
    &-progress {
        .rc-slider{
            &-handle,
            &-track {
              background-color: $primary !important;
            }
          &-handle {  
            &:active {
              box-shadow: 0 0 2px $primary !important;
            }
          }
        }
    }
}


// .overwrite-progress-bar {
//     .rc-slider{
//         &-handle,
//         &-track {
//           background-color: $primary !important;
//         }
//       &-handle {  
//         &:active {
//           box-shadow: 0 0 2px $primary !important;
//         }
//       }
//     }
//   }