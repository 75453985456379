
.headroom {
    will-change: transform;
    background-color: inherit;
    @include transition($transition-base);
}
.headroom--pinned {
	@extend .position-fixed;
    transform: translateY(0%);
}
.headroom--unpinned {
	@extend .position-fixed;
    transform: translateY(-100%);
}

.headroom--not-top {
	padding-top: .5rem;
	padding-bottom: .5rem;
    background-color: $white !important;
    box-shadow: 0 1px 10px rgba(130, 130, 134, 0.1);
}

// Hide navbar on scroll

.nav-up {
    top: -80px;
  }
  .nav-down {
    position: fixed;
    top: 0;
    transition: top 0.5s ease-in-out;
    width: 100%;
    z-index: 9999;
  }
  
//   @include media-breakpoint-down(xs) {

//     .navbar {
//       &.navbar-main {
//         .navbar-nav {
//           .dropdown .dropdown-menu {
//             max-height: 390px;
//             overflow-y: scroll;
//           }
//         }
//       }
//     }
// }

// @include media-breakpoint-down(md) {
//     .navbar-nav .dropdown-menu.show {
//       position: static !important;
//     }
//   }