/* !

 =========================================================
 * LiveNyte Customizations to existing CSS are here!
 =========================================================
 */

 * {
  -webkit-overflow-scrolling: touch;
}
body {
  background-color: #fff;
}

@font-face {
  font-family: 'icomoon';
  src:  url('livenyteFont/icomoon.eot?t7h8ts');
  src:  url('livenyteFont/icomoon.eot?t7h8ts#iefix') format('embedded-opentype'),
    url('livenyteFont/icomoon.ttf?t7h8ts') format('truetype'),
    url('livenyteFont/icomoon.woff?t7h8ts') format('woff'),
    url('livenyteFont/icomoon.svg?t7h8ts#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    // -webkit-text-fill-color: #f06482;
    transition: background-color 100000000s;
    -webkit-animation: 1ms void-animation-out;
    background: transparent !important;

}

.StripeElement--webkit-autofill,
.StripeElement--webkit-autofill:focus,
.StripeElement--webkit-autofill:hover,
.StripeElement--webkit-autofill:active{
  background: transparent !important;
}

.icon-livenyteGlyph {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-livenyteGlyph:before {
  content: "\e900";
  font-size: 1.75rem;
}

.icon-livenyteGlyph-translated {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(-7px, 3px)

}

.icon-livenyteGlyph-translated:before {
  content: "\e900";
  font-size: 1.75rem;
}

.remove-after:after {
  content: none !important;
}

.galleryPadding {
  padding-left: 7.5px;
  padding-right: 7.5px;
  padding-bottom: 15px;
  padding-top: 0px;
}

.bg-gradient-livenyte {
    background: linear-gradient(87deg, #F54EA2 0, #FF7676 100%) !important; 
}

.livenyte-title {
  font-family: 'Montserrat', sans-serif !important;
}



.cursor-pointer {
  cursor: pointer !important; }

.wrap-cursor-pointer * {
  cursor: pointer !important;
}

.wrap-cursor-not-allowed * {
  cursor: not-allowed !important;
}

.wrap-max-height * {
  max-height: inherit !important;
}

.cursor-not-allowed {
  cursor:not-allowed; }

.cursor-regular {
  cursor: default !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.translateY-10px {
  transform: translateY(10px);
}

.translateY-5px {
  transform: translateY(5px);
}

.blur {
  background-size: cover;
  background-position: center top;
  filter:blur(32px);
  -webkit-filter: blur(32px);
}

.blur {
  background-size: cover;
  background-position: center top;
  filter:blur(32px);
  -webkit-filter: blur(32px);
}

.subtitle {
  font-size: 0.8125rem;
  white-space: nowrap;
  font-weight: 400;
  line-height: 1.5;
}

//profile
.trait-subtitle {
  letter-spacing: 2px;
  font-size: 0.90rem;
  font-weight: 500;
  text-transform: uppercase;
  @include media-breakpoint-down(xs) {
    font-size: 0.75rem;
  }
}



.specialty-subtitle {
  letter-spacing: 2px;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: $gray-600;
  @include media-breakpoint-down(xs) {
    font-size: 0.70rem;
  }
}

//table small

.name-table-small {
  font-weight: 400;
  font-size: 0.8125rem;
}

.specialty-table-small {
  font-size: .75rem;
  letter-spacing: .2px;
  font-weight: 100;

}
//featured small

.name-small {
  font-weight: 400;
  font-size: 0.85rem;
}

.specialty-small {
  font-size: .80rem;
  letter-spacing: .2px;
  font-weight: 100;
}

.table-user-slim {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.livenyte-icon {
  cursor: pointer;
  padding-top: .2rem;
  font-size: 1.5rem;
}

.title {
  font-weight: 700; 
}

.table-trait-translate {
  transform: translateY(-12px);
  margin-left: 64.5px;
}

.wrap-cursor-regular * {
  cursor: default !important;
}

.display-none {
  display: none !important;
}


.icon-small {
  font-size: 15px !important; }



.circular {
  border-radius: 50%; }


.resizing-box {
  width: auto;
  height: auto;
  max-width: 100%;
}


.mt--13{
  margin-top: -13rem !important; }



.event-image {
  display: block;
  width: 100%;
  height: auto;
  max-height: 350px;
  max-width: 559px;
}

.asset-image {
  display: block;
  width: 100%;
  height: auto;
}

.special-underline-default:after {
  display:block;
  height:2px;
  background-color: #32325d;
  content: " ";
  width: 133px;
  margin: 0 auto;
  margin-top: 10px;
}

.special-underline-surtitle:after {
  display:block;
  height:2px;
  background-color: $gray-600;
  content: " ";
  width: 133px;
  margin: 0 auto;
  margin-top: 10px;
}

.special-underline-lightGray:after {
  display:block;
  height: 1px;
  background-color: $gray-400;
  content: " ";
  width: 85px;
  margin: 0 auto;
  margin-top: 15px;
}

.special-underline:after {
  display:block;
  height:2px;
  background-color: #fff;
  content: " ";
  width: 133px;
  margin: 0 auto;
  margin-top: 10px;
}

.special-underline-livenyte:after {
  display:block;
  height:2px;
  background-color: #FF4F83;
  content: " ";
  width: 133px;
  margin: 0 auto;
  margin-top: 10px;
}

.special-left-underline-livenyte:after {
  display:block;
  height:2px;
  background-color: #FF4F83;
  content: " ";
  width: 100px;
  margin: 0 0;
  margin-top: 10px;
}

.special-left-underline-info:after {
  display:block;
  height:2px;
  background-color: #11cdef;
  content: " ";
  width: 100px;
  margin: 0 0;
  margin-top: 10px;
}

.special-left-underline-tertiary:after {
  display:block;
  height:2px;
  background-color: #5e72e4;
  content: " ";
  width: 100px;
  margin: 0 0;
  margin-top: 10px;
}

.inline-block {
  display: inline-block; }

.line-styling {
  white-space: pre-wrap; }

.line-styling-breaks {
  white-space: pre-line;
}


.word-wrap {
  word-break: normal; }



.strike-through {
  text-decoration: line-through; }

.image-header {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: visible; }

/* Special Rules for Home Page */
.col-clear, .col-clear:before, .col-clear:after {
  padding-right: 0px !important;
  padding-left: 0px !important; }



.remove-text-transform {
  text-transform: none !important;
}

.align-content-bottom {
  vertical-align: bottom;
}



.max-height-350px {
  max-height: 350px;
}

.event-header-container {
  // position: absolute;
  z-index: 2;
  // max-height: 750px;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center; }

.btn-app img {
  height:48px;
  width:auto;
  margin-right: 15px;
}

@media screen and (max-width: 860px) {
  .btn-app img {
    margin-right: 10px;
    height:48px;
    width:auto;
  }
}

@media screen and (max-width: 400px) {
  .btn-app img {
    margin-right: 10px;
    height:44px;
    width:auto;
  }
}

@media screen and (max-width: 374px) {
  .btn-app img {
    margin-right: 10px;
    height:42px;
    width:auto;
  }
}



.btn-app:link,
.btn-app:visited {
  border: 0;
  
}

.one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.two-line {
  line-height: 1.5em;
  height: 3em;       /* height is 2x line-height, so two lines will display */
  overflow: hidden;
  text-overflow: ellipsis;
}

.four-line {
  line-height: 1.5em;
  max-height: 6em;       /* height is 2x line-height, so two lines will display */
  overflow: hidden;
  text-overflow: ellipsis;
}

.seven-line {
  line-height: 1.5em;
  max-height: 10.5em;       /* height is 2x line-height, so two lines will display */
  overflow: hidden;
  text-overflow: ellipsis;
}

.eleven-line {
  line-height: 1.5em;
  max-height: 16.5em;       /* height is 2x line-height, so two lines will display */
  overflow: hidden;
  text-overflow: ellipsis;
}

.filepond--credits { 
  display: none;
}

.dashboard-title {
  font-size: 2rem;
}

.headerVideo {
  object-fit: cover;
  height: 80vh;
  filter: brightness(0.91);
}

.card-box-shadow {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
}


.no-box-shadow {
  box-shadow: 0 0 0 0
}


.bg-transparent-smoke {
  background-color: rgba(247, 250, 252, 0.1);
}

.bg-transparent-carbon {
  background-color: rgba(0, 0, 0, 0.75);
}

.surtitle-gray {
  color: $gray-600;

}

.progressiveVideoContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.progressiveVideo {
  position: absolute;
  width: auto;
  height: 100%;
  top: 0;
  left: 0;
}

.progressiveVideo-thumb {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: opacity 400ms ease 0ms;
}
.progressiveVideo-tiny {
  filter: blur(20px);
  // transform: scale(1.1);
  transition: visibility 0ms ease 400ms;
  height: 80vh;
}

.objectFitCover {
  object-fit: cover;
}

.z98 {
  z-index: 98;
}
