// HEADER 4

.header-4 {
  .navbar {
    z-index: 2;
    position: absolute;
  }

  .header-video {
    overflow: hidden !important;
    display: grid !important;

    video {
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      z-index: 0;
      -ms-transform: translateX(-50%) translateY(-50%);
      -moz-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .header-video .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    min-width: 101%;
    min-height: 100%;
    // background-color: rgb(34,19,51);
    opacity: .3;
    z-index: 1;
  }

  .video-text {
    font-size: 5em;
    color: $white;
  }

}